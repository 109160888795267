import React, { Fragment, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { IsMobileDevice,  } from "../utils/functions";
import { useRecoilState } from "recoil";
import { isMobile, Mode } from "../recoil/atoms";
import ContactForm from "../components/ContactForm/ContactForm";
import SEO from "../components/seo";

const Contact = () => {
  const [, setIsMobile] = useRecoilState(isMobile);
  const [, setMode] = useRecoilState(Mode);
  useEffect(() => {
    setIsMobile(IsMobileDevice());
    setMode(localStorage.theme);
  });

  return (
    <Fragment>
      <SEO title="Innovation Hub" description="Somos una startup con base en Buenos Aires que provee equipos on demand de alta calidad para trabajar en proyectos de innovación y tecnología, con foco en diseño UX, desarrollo de software e ingeniería."/>
      <Layout>
        <ContactForm />
      </Layout>
    </Fragment>
  );
};

export default Contact;

// export const data = graphql`
// RELLENAR (?)
// `
