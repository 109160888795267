import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import {
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { ModeSelector } from "../../recoil/selectors";
import styles from "./contactform.module.scss";
import BotonArchivo from "../../assets/images/ContactForm/botonarchivo.svg";
import BotonArchivoBco from "../../assets/images/ContactForm/botonarchivobco.svg";
import arrowDown from "../../assets/images/arrowGray.png";
import firebase from "gatsby-plugin-firebase"

const ContactForm = () => {

  const [fullStack, setFullStack] = useState(false);
  const [frontEnd, setFrontEnd] = useState(false);
  const [backEnd, setBackEnd] = useState(false);
  const [industrial, setIndustrial] = useState(false);
  const [uiux, setUiux] = useState(false);
  const [electronico, setElectronico] = useState(false);
  const [projectManager, setProjectManager] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [title1, setTitle1] = useState(false);
  const [title2, setTitle2] = useState("Hola");
  const [text, setText] = useState("Hola itesa");
  const [infoMode, setInfoMode] = useState("Hola");
  const [url, setUrl] = useState("");
  const [file,setFile] = useState(false)
  const [status,setStatus] = useState("ENVIAR")
  const [added, setAdded] = useState(false)
  const [uploadValue, setUploadValue] = useState()
  const mode = useRecoilValue(ModeSelector);
  

  useEffect(() => {
    if (window.history.state) {
      setTitle1(window.history.state.title1);
      setTitle2(window.history.state.title2);
      setText(window.history.state.text);
      setInfoMode(window.history.state.mode);
    }
  });

  async function handleImageUpload(event) {
    setFile(true)
    uploadFile(event.target.files[0]);
  }

  async function uploadFile(file) {
    try {
      const storageRef = firebase.storage().ref(`CVS/${email}`);
      const task = storageRef.put(file);
      task.on(
        "state_changed",
        snapshot => {
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadValue(percentage)
        },
        error => {
          console.error(error.message);
        },
        () => {
          task.snapshot.ref.getDownloadURL().then(url => {
            setUrl(url)
            firebase.firestore()
              .collection("CVS")
              .add({
                link: url,
                name: name,
                email: email,
                date: new Date().toISOString()
              })
              .then(function(docRef) {
                firebase
                  .firestore()
                  .collection("CVS")
                  .doc(docRef.id)
                  .update({ id: docRef.id });
              }).then(setAdded(true));
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  }


  const submitForm = (e) => {
    e.preventDefault();
    let techs = [];
    if (frontEnd) {
      techs.push("FrontEnd");
    }
    if (backEnd) {
      techs.push("BackEnd");
    }
    if (fullStack) {
      techs.push("FullStack");
    }
    if (marketing) {
      techs.push("Marketing Digital");
    }
    if (uiux) {
      techs.push("UI/UX Design");
    }
    if (projectManager) {
      techs.push("Project Manager");
    }
    if (industrial) {
      techs.push("Diseñador Industrial");
    }
    if (electronico) {
      techs.push("Electrónico");
    }
    let formSubmission = {
      infoMode,
      techs,
      name,
      email,
      web,
      mensaje,
      url,
      // test : "agus@itesa.co"
    };
    firebase.firestore()
    .collection("contactos")
    .add({
      infoMode,
      techs,
      name,
      email,
      web,
      mensaje,
      url,
      date: new Date().toISOString()
    })
    // console.log(formSubmission)
    // https://us-central1-webitesa.cloudfunctions.net/contact
    fetch('https://us-central1-webitesa.cloudfunctions.net/contact', {
      method: 'POST',
      body: JSON.stringify({formSubmission}),
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
          // if(response.ok) {
            setBackEnd("")
            setElectronico(false)
            setEmail("")
            setFrontEnd(false)
            setIndustrial(false)
            setMensaje("")
            setProjectManager(false)
            setUiux(false)
            setWeb("")
            setMarketing(false)
            setFullStack(false)
            setName("")
            setStatus("ENVIADO")
                setTimeout(() => {
                  setStatus("ENVIAR")
            }, 3000);
          // }else{
          //   console.error("Error enviando los datos. \n", response);
          // }
    })
    .catch((err)=>{
      console.error("Error",err)
    })
    setStatus("ENVIANDO...")
  };
  return (
    <div className={styles.ContactFormContainer}>
      <div className={styles.back}>
        <Link to="/" className={styles.arrowLink}>
            <Button className={styles.ButtonArrow}>
                <img alt="arrow" src={arrowDown} className={styles.Arrow} />
            </Button>
            <small className={styles.smalltxt}>Volver {}</small>
        </Link>
        <div style={{ width: "100%", textAlign: "left" , marginTop:"30px" }}>
            {" "}
            <small style={{ color: "grey" }}>
            {mode === "light" ? ( <Link style={{ color: "black" }} to="/">
                Home{" "}
            </Link>):( <Link style={{ color: "white" }} to="/">
                Home{" "}
            </Link>)}
            <span>/</span> Contacto
            </small>
        </div>
      </div>
      <div className={styles.fullcont}>
        <div className={`${styles.textbar}`}>
          <p className={`${styles.itesa} animated fadeIn`}>ITESA</p>
          <p
            className={`${styles.title} fadeIn-left`}
            style={{ color: mode === "dark" || !mode ? "white" : "black" }}
          >
            {title1}
          </p>
          <p
            style={{ color: mode === "dark" || !mode ? "white" : "black" }}
            className={`${styles.title} fadeIn-left`}
          >
            {title2}
            <span>.</span>
          </p>
          <p
            style={{ color: mode === "dark" || !mode ? "white" : "black" }}
            className={`${styles.text} fadeIn-left`}
          >
            {text}
          </p>
        </div>
        {/* ////// FORM CONTAINER ////////// */}
        <div className={styles.formcontainer}>
          <small
            style={{ color: "gray", display: infoMode !== "CV" && "none" }}
          >
            Seleccioná tu área de interés
          </small>
          {/* ////// TOGGLE BUTTON ////////// */}
          <div
            style={{ display: infoMode !== "CV" && "none" }}
            className={styles.toggleContainer}
          >
            <div 
              tabIndex={0}
              role="button"
              className={frontEnd ? styles.toggleButtonAct : styles.toggleButton}
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setFrontEnd(!frontEnd);
              }}
              onKeyPress={()=>setFrontEnd(!frontEnd)}
            >
              Desarrollador FrontEnd
            </div>
            <div 
              tabIndex={0}
              role="button"
              className={
                backEnd ? styles.toggleButtonAct : styles.toggleButton
              }
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setBackEnd(!backEnd);
              }}
              onKeyPress={()=>setBackEnd(!backEnd)}
            >
              Desarrollador BackEnd
            </div>
            <div
              tabIndex={0}
              role="button"
              className={fullStack ? styles.toggleButtonAct : styles.toggleButton}
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setFullStack(!fullStack);
              }}
              onKeyPress={()=>setFullStack(!fullStack)}
            >
              Desarrollador Full Stack
            </div>
            <div
              tabIndex={0}
              role="button"
              className={uiux ? styles.toggleButtonAct : styles.toggleButton}
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setUiux(!uiux);
              }}
              onKeyPress={()=>setUiux(!uiux)}
            >
              Diseñador UI/UX
            </div>
            <div
              tabIndex={0}
              role="button"
              className={industrial ? styles.toggleButtonAct : styles.toggleButton}
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setIndustrial(!industrial);
              }}
              onKeyPress={()=>setIndustrial(!industrial)}
            >
              Diseñador Industrial
            </div>
            <div
              tabIndex={0}
              role="button"
              className={electronico ? styles.toggleButtonAct : styles.toggleButton}
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setElectronico(!electronico);
              }}
              onKeyPress={()=>setElectronico(!electronico)}
            >
              Electrónico
            </div>
            <div
              tabIndex={0}
              role="button"
              className={
                projectManager ? styles.toggleButtonAct : styles.toggleButton
              }
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setProjectManager(!projectManager);
              }}
              onKeyPress={()=>setProjectManager(!projectManager)}
            >
              Project Manager
            </div>
            <div
              tabIndex={0}
              role="button"
              className={marketing ? styles.toggleButtonAct : styles.toggleButton}
              style={{ color: mode === "dark" || !mode ? "white" : "black" }}
              onClick={() => {
                setMarketing(!marketing);
              }}
              onKeyPress={()=>setMarketing(!marketing)}
            >
              Marketing Digital
            </div>
          </div>
          {/* ////// FORM ////////// */}
          <Form onSubmit={(e) => submitForm(e)} className={styles.form}>
            <Form.Row className={styles.formrow}>
              <Col md="4">
                <Form.Control
                  
                  style={{
                    color: mode === "dark" || !mode ? "white" : "black",
                  }}
                  className={`${styles.input} ${styles.nameinput}`}
                  type="text"
                  placeholder="Tu Nombre"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Col>
              <Col md="8">
                <Form.Control
                  
                  style={{
                    color: mode === "dark" || !mode ? "white" : "black",
                  }}
                  className={styles.input}
                  type="email"
                  placeholder="Tu Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row
              style={{ display: infoMode !== "CV" && "none" }}
              className={styles.formrow}
            >
              <Col md="12">
                <Form.Control
                  
                  style={{
                    color: mode === "dark" || !mode ? "white" : "black",
                  }}
                  className={styles.input}
                  type="text"
                  placeholder="Web/Portfolio"
                  value={web}
                  onChange={(e) => {
                    setWeb(e.target.value);
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row className={styles.formrow}>
              <Col md="12">
                <Form.Control
                  
                  style={{
                    color: mode === "dark" || !mode ? "white" : "black",
                  }}
                  className={styles.input}
                  type="text"
                  as="textarea"
                  rows={4}
                  placeholder="Mensaje"
                  value={mensaje}
                  onChange={(e) => {
                    setMensaje(e.target.value);
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row
              style={{ display: infoMode !== "CV" && "none" }}
              className={styles.formrow}
            >
              <Col md="12">
                <button
                  className={styles.btn}
                  type="button"
                  //onChange={event => handleImageUpload(event)}
                >
                  <label htmlFor="file-upload" className={name && email ? styles.customFileUploadOk : styles.customFileUpload}>
                    <img
                      className={styles.img}
                      src={name && email
                          ? BotonArchivoBco
                          : BotonArchivo
                      }
                      alt="Click para subir una imagen de la memoria"
                    />{" "}
                    <input
                      id="file-upload"
                      type="file"
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                      text/plain, application/pdf, image/*"
                      className={styles.hide}
                      disabled={name && email ? false : true}
                      onChange={handleImageUpload}
                    />
                  </label>
                    {file && !added ? <progress value={uploadValue} max="100">{uploadValue}%</progress> : null}
                </button>
              </Col>
            </Form.Row>
            <Form.Row className={styles.formrow}>
              <Col md="4">
                <Button type="submit" disabled={file && !added ? true : false}  className={file && !added ? styles.submitDisabled : styles.submit}>
                  {status}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
